//@flow
import { oauthHeader } from "../helpers/oauth-header";
import { urlsConstants } from "../constants/url.constant";
import { objectsConstants } from "../constants/objects.constants";
import { defaultService } from "./defaultService";
import axios from "axios/index";
import _ from "lodash";
export const userService = {
  me,
  getCurrentUser,
  existByCpf,
  findByFilter,
  findTableColumns,
  getProfiles,
  getAllProfiles,
  ativarUsuario,
  inativarUsuario,
  exportToExcel,
  findSearchableColumns,
  doGet,
  doSave,
  ativarUsuarioByFilter,
  inativarUsuarioByFilter,
  getExtensionUser,
  isConsultorio,
  findByPerfil,
  isAuditor,
  isPerfil,
  esqueciSenha,
  isCooperado,
  getPerfilAtual,
  getColorDefault,
  findMyProfiles,
  setPerfilAtual,
  setCooperativaAtualSudo,
  setAviso,
  getAviso,
  isPerfilCooperativa,
  salvarBandeirinhas,
  isSudo,
};

// the :export directive is the magic sauce for webpack
const perfilClass = {
  PROTOCOLO: "avatarPerfil_Protocolo", //Protocolo
  AUDITOR: "avatarPerfil_Auditor", //Auditor
  CONSULTORIO: "avatarPerfil_Consultorio", //Consultorio
  COOPERADO: "avatarPerfil_Cooperado", //Cooperado
  ADMINISTRADOR: "avatarPerfil_Admin", //Administrador
};

function getColorDefault (user,tipoPerfil) {
  if(!user.usuarioPerfis && tipoPerfil){
    return perfilClass[tipoPerfil];
  }if(user.perfilAtual){
    return perfilClass[user.perfilAtual.perfil.tipoPerfil];
  }
  else
  {
    if(user && user.usuarioPerfis){
      return user.usuarioPerfis.map(perfil => {
        return perfilClass[perfil.perfil.tipoPerfil];
      });
    }else{
      return perfilClass[tipoPerfil];
    }
    
  }
  
};

function getExtensionUser(user) {
  if (isAuditor(user)) {
    return null;
  }
  if(user.perfilAtual){
    return _.get(user.perfilAtual, "extensao");
  }
  return _.get(user.usuarioPerfis[0], "extensao");
}
function doGet(id) {
  return defaultService.doGet(`${urlsConstants.USUARIO}${id}`);
}
function findByPerfil(perfil) {
  return defaultService.doGet(`${urlsConstants.USUARIO}findByPerfil/${perfil}`);
}
function doSave(entity: any) {

  return defaultService.doSave(urlsConstants.USUARIO, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel("usuario", filter, fields, labels);
}

function setPerfilAtual(idPerfil) {
  return defaultService.doGet(`${urlsConstants.USUARIO}setPerfilAtual/${idPerfil}`);
}
function findMyProfiles(){

    return defaultService.doGet(`${urlsConstants.USUARIO}findMyProfiles`);

}
function getProfiles() {
  return defaultService.doGet(`${urlsConstants.USUARIO}profiles`);
}

function getAllProfiles() {
  return defaultService.doGet(`${urlsConstants.USUARIO}allprofiles`);
}

function existByCpf(cpf) {
  return defaultService.doGet(`${urlsConstants.USER_PUBLIC}/${cpf}`);
}

function esqueciSenha(email) {
  return defaultService.doGet(
    `${urlsConstants.USER_PUBLIC}/esqueciSenha/${email}`
  );
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.USUARIO}list/fields`);
}

function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.USUARIO}search/fields`);
}

function inativarUsuario(ids) {
  return defaultService.doPost(`${urlsConstants.USUARIO}inativar`, ids);
}

function ativarUsuario(ids) {
  return defaultService.doPost(`${urlsConstants.USUARIO}ativar`, ids);
}

function salvarBandeirinhas(entity) {
  return defaultService.doPost(`${urlsConstants.USUARIO}salvarBandeirinhas`, entity);
}

function ativarUsuarioByFilter(listFields) {
  return defaultService.doPost(`${urlsConstants.USUARIO}ativarByFilter`, {
    pageSize: objectsConstants.PAGE_MAX,
    page: 0,
    listFields
  });
}

function inativarUsuarioByFilter(listFields) {
  return defaultService.doPost(`${urlsConstants.USUARIO}inativarByFilter`, {
    pageSize: objectsConstants.PAGE_MAX,
    page: 0,
    listFields
  });
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.USUARIO}list`,
    options,
    filter
  );
}

async function me() {
  const requestOptions = {
    method: "GET",
    headers: oauthHeader()
  };
  return await defaultService.doGet(urlsConstants.USER_ME).then(response => {
    if (response.data.error === "invalid_token" || response.status === 401) {
      localStorage.removeItem("token");
      return Promise.reject("invalid_token");
    }
    localStorage.setItem("user", JSON.stringify(response.data));
    return response;
  });
}

function getCurrentUser() {
  let token = localStorage.getItem("user");
  if (!token) {
    return null;
  }
  let user = JSON.parse(token);

  let aviso = localStorage.getItem("aviso");
  if (!aviso) {
    setAviso(true);
  }

  return user;
}

function isCooperado(entity) {
  return isPerfil(entity, objectsConstants.COOPERADO);
}

function isConsultorio(entity) {
  return isPerfil(entity, objectsConstants.CONSULTORIO);
}

function isAuditor(entity) {
  return isPerfil(entity, objectsConstants.AUDITOR);
}

function isPerfil(entity, perfil) {
  
  if (entity.perfilAtual) {
    return entity.perfilAtual.perfil.tipoPerfil === perfil;
  }

  if (entity.usuarioPerfis && entity.usuarioPerfis.length) {
    for (let i = 0; i < entity.usuarioPerfis.length; i++) {
      if (
        entity &&
        entity.usuarioPerfis &&
        entity.usuarioPerfis[i].perfil.tipoPerfil === perfil
      ) {
        return true;
      }
    }
  }

  return false;
}
function setCooperativaAtualSudo(idCooperativa){
  return defaultService.doGet(`${urlsConstants.USUARIO}setCooperativaSudo/${idCooperativa}`);
}
function getPerfilAtual(user) {
  if (user.perfilAtual) {
    return user.perfilAtual.perfil.nome;
  }
  return user.usuarioPerfis.map(perfil => {
    return perfil.perfil.nome;
  });
}

function setAviso(aviso) {
  localStorage.setItem("aviso", JSON.stringify(aviso));
}

function getAviso() {
  return JSON.parse(localStorage.getItem("aviso"));
}

function isPerfilCooperativa () {
  if (getCurrentUser()) {
    const perfil = getPerfilAtual(getCurrentUser());
    if (perfil) {
      return perfil === "Administrador" || perfil === "Protocolo" || perfil === "Auditor";
    }
  }
  return false;
}

function isSudo () {
  if (getCurrentUser()) {
    const perfil = getPerfilAtual(getCurrentUser());
    if (perfil) {
      return perfil === "Sudo";
    }
  }
  return false;
}
